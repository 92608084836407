<template>
  <div style="min-height: 400px">
    <div
      class="row"
      v-if="incomingClubParties.length"
      style="padding: 10px"
      :style="{ background: `linear-gradient(${clubColor}, black)` }"
      id="incoming-events"
    >
      <h4 class="section-header text-left" v-if="this.size === 'lg'">
        <img
          alt="upcoming parties"
          v-lazy="logo + '?w=40&h=40&fit=clamp'"
          height="40px"
          width="40px"
          style="border-radius: 300px"
        />Upcoming Events ({{ incomingClubParties.length }})
      </h4>

      <h4 v-if="incomingClubParties.length && this.size !== 'lg'" class="section-header text-left"
          style="font-size:0.8rem"
      >
        <img
          v-lazy="logo + '?w=20&h=20&fit=clamp'"
          height="20px"
          width="20px"
          style="border-radius: 300px"
        />Upcoming Events ({{ incomingClubParties.length }})

      </h4>

      <br v-if="incomingClubParties.length"/>

      <p style="margin-left: 50px;font-size: 0.6rem;margin-top: -35px"
         v-if="this.size === 'lg'"
      > DINNER, SHOWS & TICKETS
      </p>
      <br v-if="incomingClubParties.length"/>

      <div v-for="(event, idx) in incomingClubParties.slice(0,  5)"
           :key="idx"
           :style="{ background: `linear-gradient(${clubColor}, black)` }"
           class="card">
        <MiniPartyCard
          v-if="event.metadata.magicEvent"
          v-bind:event="event"
          class="card-animation-on-hover "
          :key="idx"
          :idx="idx"
        ></MiniPartyCard>
        <MiniEventCard
          with-brand="true"
          without-brand="true"
          v-if="!event.metadata.magicEvent"
          v-bind:event="event"
          class="card-animation-on-hover "
          :idx="idx"
          :key="idx"
        ></MiniEventCard>
      </div>

      <div v-if="showAll">
        <div v-for="(event, idx) in incomingClubParties.slice(5,incomingClubParties.length)"
             :style="{ background: `linear-gradient(${clubColor}, black)` }"
             :key="idx"
             class="card"
        >
          <MiniPartyCard
            v-if="event.metadata.magicEvent"
            v-bind:event="event"
            class="card-animation-on-hover "
            :idx="idx"
          ></MiniPartyCard>
          <MiniEventCard
            with-brand="true"
            without-brand="true"
            :idx="idx"
            v-if="!event.metadata.magicEvent"
            v-bind:event="event"
            class="card-animation-on-hover "
          ></MiniEventCard>
        </div>
      </div>
      <br>
      <div v-if="incomingClubParties.length > 6" class="centered card" style="margin: 20px">
        <div class="text-center centered card" v-if="!showAll">
          <p class="btn-link btn-info text-center" @click="showAll = true"
             :style="{ background: `linear-gradient(${clubColor}, black)` }"
          >SHOW ALL
            (5-{{ incomingClubParties.length }})</p>
        </div>
      </div>

    </div>
    <div
      class="row"
      v-if="!incomingClubParties.length"
      style="padding: 10px"
      :style="{ background: `linear-gradient(${clubColor}, black)` }"
    >
      <h4 class="section-header text-left" v-if="this.size === 'lg'">
        <img
          alt="upcoming parties"
          v-lazy="logo + '?w=40&h=40&fit=clamp'"
          height="40px"
          width="40px"
          style="border-radius: 300px"
        />&nbsp;&nbsp;&nbsp; No Upcoming Events ({{ incomingClubParties.length }})
      </h4>
      <h4 class="section-header text-left" v-if="this.size !== 'lg'"
          style="font-size:0.8rem">
        <img
          alt="upcoming parties"
          v-lazy="logo + '?w=40&h=40&fit=clamp'"
          height="40px"
          width="40px"
          style="border-radius: 300px"
        />&nbsp;&nbsp;&nbsp;
        No Upcoming Events
      </h4>
    </div>
    <div v-if="incomingClubParties.length < 3" class="text-center card centered">
      <router-link :to="{path: '/los-cabos-mexico-event-calendar'}">
        <div class="card text-center" style="padding: 20px">
          <img class="centered" v-lazy="djs+'?w=259&h=259&fit=fillmax&fill=blur'" width="259px" height="259px"
               alt="more">
          <hr>
          <p>Check our calendar for the full list of events</p>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import MiniEventCard from '@/components/EventCard/MiniEventCard'
import MiniPartyCard from "@/components/EventCard/MiniPartyCard"

export default {
  name: 'UpcomingEvents',
  components: {MiniEventCard, MiniPartyCard},
  data: () => {
    return {
      showAll: false,
      djs:
        'https://imgix.cosmicjs.com/1eb5e990-f3e6-11ee-b555-0d0678c27dd7-Bits-of-color.jpg',
    }
  },
  props: {
    clubConfig: {},
    clubColor: {
      type: String,
    },
    size: {
      type: String,
    },
    logo: {
      type: String,
      default: '',
    },
    incomingClubParties: {type: Array},
  }
}
</script>
