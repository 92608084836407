<template>
  <div>

    <div class="row text-left">
      <h4 style="font-size: 13px; font-weight: bold; margin-top: 25px">
        📍Address
      </h4>
      <address>
        {{ this.config.directions.address }}
      </address>
    </div>
    <div class="row" style="min-height: 250px;background: url('https://imgix.cosmicjs.com/a47ded40-484a-11ee-89ab-17371fc03105-image.png?h=250') center no-repeat;">
      <iframe
        :src="`https://www.google.com/maps/embed?${this.config.directions.googleMap}`"
        width="100%" height="310" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade">
      </iframe>
    </div>
    <!--    <a :href="this.config.directions.googleMap" class="btn btn-danger btn-sm">Google Map</a>-->
<!--    <a :href="this.config.directions.wazeMap" class="btn btn-info btn-sm">WAZE</a>-->
  </div>
</template>
<script>
export default {
  name: 'ClubMapTable',
  props: {
    config: {
      type: Object, // striped | hover
      default: () => {
        return {directions: {address: '-- --'}}
      }
    },
  },

};
</script>
