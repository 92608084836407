<template>
  <div class="content">
    <article class="row" style="background: #1f1e1f ;">
      <section class="text-center col-xl-8 col-sm-12">


        <HeadImage :image="clubImage" align="text-center" :title="club.title"
                   text-transform="uppercase"
                   :color="clubColor"
                   :caption="this.caption"></HeadImage>

        <div v-if="incomingClubParties.length">

          <img
            v-lazy="this.logo + '?w=40w=40&fit=clamp'"
            height="40px"
            width="40px"
            style="border-radius: 100px; margin-right: -20px; z-index: 2"
          />
          <img
            v-lazy="
              incomingClubParties[0].metadata.main_image.imgix_url +
              '?w=40w=40&fit=clamp'
            "
            :alt="incomingClubParties[0].title"
            height="40px"
            width="40px"
            style="border-radius: 100px;border: 2px solid black"
          />
          <a
            style="margin-left: 5px"
            class="text-left d-inline-lg "
            v-if="incomingClubParties.length"
            href="#incoming-events"
          >Upcoming Events
            ({{ incomingClubParties.length }})
          </a>

        </div>


        <div
          class="card text-center col-md-12 col-md-10 offset-md-1"
          style="z-index: 1; margin: 2px;min-height: 500px"
        >
          <div class="card">
            <ul class="nav nav-tabs flex-row">
              <li
                role="presentation"
                class="nav-item"
                :class="{ active: this.showDetailsTab }"
              >
                <div class="text-center">
                  <a @click="showTab('details')" class="card-animation-on-hover"
                  >INFO</a
                  >
                </div>
              </li>

              <li
                role="presentation"
                class="nav-item"
                :class="{ active: this.showFaqsTab }"
              >
                <div class="text-center">
                  <a @click="showTab('faqs')" class="card-animation-on-hover"
                  >FAQS</a
                  >
                </div>
              </li>
              <li
                role="presentation"
                class="nav-item"
                :class="{ active: this.showLocationTab }"
              >
                <div class="text-center">
                  <a
                    @click="showTab('location')"
                    class="card-animation-on-hover"
                  >MAP</a
                  >
                </div>
              </li>
            </ul>
            <div id="container" class="tab-content">
              <div
                role="tabpanel"
                class="tab-pane"
                id="details"
                v-if="this.showDetailsTab"
                :class="{ active: this.showDetailsTab }"
              >
                <ClubDetails :config="this.clubTagsConfig" :color="this.clubColor"></ClubDetails>
                <br/>
                <div
                  class="text-center"
                  v-html="club.metadata && club.metadata.description"
                  style="color: #fff !important; margin-bottom: 10px"
                ></div>
                <br/>
              </div>
              <div
                role="tabpanel"
                class="tab-pane"
                id="location"
                v-if="this.showLocationTab"
                :class="{ active: this.showLocationTab }"
              >
                <ClubMapTable :config="this.clubTagsConfig"></ClubMapTable>
              </div>
              <div
                role="tabpanel"
                class="tab-pane"
                id="faqs"
                v-if="this.showFaqsTab"
                :class="{ active: this.showFaqsTab }"
              >
                <ClubFaqs :club-slug="this.slug"></ClubFaqs>
              </div>
              <div class="card"
                   :style="{ background: `linear-gradient(${this.secondaryColor}, black)` }">
                <img
                  v-lazy="astroImg + '?h=30'"
                  height="30px"
                  width="30px"
                  alt="cabo party support"
                  class="d-inline-lg "
                  style="
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    margin-top: 20px;
                  "
                />
                <br/>
                <p>Secure your spot at <strong><br>{{ club.title }}</strong></p>
                <br/>

                <RsvpViaDialog
                  text="INFO & RSVP"
                  :club-config="this.rsvpConfig">
                </RsvpViaDialog>
                <br/>
                <small
                >We operate from 9AM - 11PM / Answer within 15 mins</small
                >
                <br>
              </div>
            </div>
          </div>
          <div class="card text-left" style="margin-top: 1px">
            <div
              v-html="club.metadata.content"
              class="md-small-hide"
              style="
                color: #fff !important;
                margin-bottom: 1px;
                line-height: 1.4rem;
              "
            ></div>
          </div>


          <div
            v-if="incomingClubParties.length && !incomingClubParties[0].metadata.magicEvent"
            class="row"
            style="padding: 10px"
            :style="{
            background: `linear-gradient(${this.secondaryColor}, black)`,
          }"
          >
            <h4 class="section-header text-left">
              <img
                v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
                height="40px"
                width="40px"
                style="border-radius: 300px"
              />
              Next Event</h4>

            <div class="centered">

              <h3 class="">{{ incomingClubParties[0].title.split("|")[0] }}</h3>
              <EventCard
                :withLineup="false"
                :with-title="false"
                v-bind:event="incomingClubParties[0]"
              >
              </EventCard>
            </div>
          </div>
          <UpcomingEvents :logo="this.logo" :club-color="clubColor" :club-config="clubConfig" size="lg"
                          :incoming-club-parties="incomingClubParties" />

          <div id="carrousel" style="margin-top: 30px">
            <h3 class="text-left section-header">
              <img
                v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
                height="40px"
                width="40px"
                style="border-radius: 300px;margin-right: 10px;"
              />
              GALLERY
            </h3>
            <Carrousel :folder="'clubs-'+this.slug" :color="clubColor"></Carrousel>
          </div>

          <div v-if="this.video" style="margin-top: 35px"
               :style="{ backgroundImage: `linear-gradient(to bottom, ${this.clubColor}, #050404)`, }">
            <h3 class="text-left section-header">
              <img
                v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
                height="40px"
                width="40px"
                style="border-radius: 300px;margin-right: 10px;"
              />ACTION
            </h3>
            <hr
              style="height: 2px; margin-top: -10px"
              :style="{ backgroundColor: this.clubColor }"
            />
            <club-video-album :video="this.video"></club-video-album>
          </div>
          <div class="card" style="margin-top: 45px;"
          >
            <h3
              style="
                margin-bottom: 0;
                margin-top: 30px;
                color: #ffffff !important;
                font-weight: bold;
                font-size: 1.3rem;
              "
            >
              📲 INFO & RSVP
            </h3>
            <hr/>
            <p>
              Secure your spot at <strong>{{ club.title }}</strong
            >.<br> Have questions or need more details? <br><strong>We're here to help.</strong>
            </p>
            <br>
            <Reservations :club="this.code" :color="this.clubColor"></Reservations>
            <small>We operate from 9AM - 10PM / Answer within 15 mins</small>

          </div>
          <br/>
        </div>

        <br/>

        <br/>
        <ClubSearch
          :clubs="this.clubs"
          style="position: fixed; bottom: -10px; z-index: 10"
        ></ClubSearch>
      </section>

      <div class="card col-4 col-xl-4 d-none d-xl-inline">
        <br>
        <h4 class="section-header" style="font-weight: bold">
          <i class="tim-icons icon-chat-33" style="padding: 5px;border-radius: 100px;"
             :style="`{border: '1px solid ${this.secondaryColor}'`"
          ></i> INFO & RSVP
        </h4>


        <div class="row"

        >


          <div class="col-sm-6">
            <p>
              <strong>Have questions or need more details?</strong> We're here
              to help.
            </p>
          </div>
          <div class="col-sm-6 text-center">
            <img v-lazy="astroImg + '?h=30'" height="30px" class="text-center d-none d-xl-inline"
                 style="margin-bottom: 30px"/>
            <br>
            <RsvpViaDialog
              :club-config="this.rsvpConfig"
              text="RSVP"
            ></RsvpViaDialog>
            <small>Secure your spot</small>

          </div>
        </div>

        <br>
        <br>
        <br>

        <UpcomingEvents :logo="this.logo" :club-color="clubColor" :club-config="clubConfig" :incoming-club-parties="incomingClubParties" />
        <ClubsSmartMenu :links="links" :primary-color="this.clubColor" :secondary-color="this.secondaryColor">
        </ClubsSmartMenu>

      </div>
    </article>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Reservations from '../../components/Reservations/Reservations';
import clubsConfig from '../../common/clubsConfig';
import clubsTableConfig from '../../common/clubsTableConfig';
import ClubSearch from '@/components/Clubs/ClubSearch';
import ClubDetails from '@/components/Clubs/ClubDetailsTable';
import ClubMapTable from '@/components/Clubs/ClubMapTable';
import EventCard from "@/components/EventCard/EventCard";
import ClubVideoAlbum from '@/components/VideoAlbum/ClubVideoAlbum';
import ClubFaqs from '@/components/Clubs/ClubFaqs';
import {slugDecoder} from '@/common/Decoder';
import Carrousel from '@/components/Carrousel';
import ClubsSmartMenu from "@/components/SmartMenu/ClubsSmartMenu";
import UpcomingEvents from "@/components/UpcomingEvents/UpcomingEvents";
import HeadImage from "@/components/HeadImage/HeadImage";

const RsvpViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpViaDialog'
    );

export default {
  name: 'Club',
  props: ['slug'],
  metaInfo() {
    const ClubName =
      this.club.title || this.slug.replace(/-/g, ' ').toUpperCase();
    const clubDescription = this.club.metadata.content || '';
    return {
      title: `${ClubName} | Reservations · Dinner, Shows & Events.`,
      meta: [
        {
          name: 'description',
          content: `${ClubName} · Dinner, Shows & Events. ${clubDescription}`,
        },
        {
          name: 'keywords',
          content: `Cabo, San Jose Cao, Cabo San Lucas, Clubs, Nightclubs, ${this.club.title} party, reservations, bookings, club, nightclub`,
        },
        {
          property: 'og:title',
          content: `${this.club.title} · Reservations · Upcoming Events · Tickets`,
        },
        {
          property: 'og:url',
          content: `https://www.cabo.party/los-cabos-clubs/${this.slug}`,
        },
        {
          property: 'og:description',
          content: `${ClubName} · Upcoming Events, Tickets & Reservations.`,
        },
        {property: 'og:type', content: 'article'},
        {
          property: 'og:image',
          content: this.clubImage,
        },
      ],
    };
  },
  data: () => {
    return {
      showDetailsTab: true,
      showFaqsTab: false,
      showLocationTab: false,
      showReviewTab: false,
      video: false,
      showPalms: false,
      clubStyle: '',
      clubConfig: {},
      rsvpConfig: {},
      clubTagsConfig: {},
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
      astroImg:
        'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      bgPalm:
        'https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=aquamarine,FA8072&duotone-alpha=100',
      clubColor: 'aquamarine',
      secondaryColor: 'black'
    };
  },
  methods: {
    showTab(tab) {
      if (tab === 'location') {
        this.showLocationTab = true;
        this.showDetailsTab = false;
        this.showReviewTab = false;
        this.showFaqsTab = false;
      }
      if (tab === 'details') {
        this.showLocationTab = false;
        this.showReviewTab = false;
        this.showDetailsTab = true;
        this.showFaqsTab = false;
      }
      if (tab === 'faqs') {
        this.showLocationTab = false;
        this.showReviewTab = false;
        this.showDetailsTab = false;
        this.showFaqsTab = true;
      }
    },
  },
  components: {
    HeadImage,
    UpcomingEvents,
    ClubsSmartMenu,
    Carrousel,
    ClubFaqs,
    ClubMapTable,
    ClubDetails,
    EventCard,
    RsvpViaDialog,
    Reservations,
    ClubVideoAlbum,
    ClubSearch,
  },
  computed:
    mapState({
      incomingClubParties: (state) => {
        return state.status.loading ?  state.loadingParties.slice(0, 6) : (state.incomingClubParties[state.venue.slug] || [])
      },
      club: (state) => state.venue,
      clubs: (state) => state.venues,
      clubImage: (state) =>
        state.venue.metadata ? state.venue.metadata.main_image.imgix_url : '',
      links: (state) => state.links,
    }),
  created() {
    this.clubConfig = clubsConfig[this.slug] || { color: '#313742', bgPalm: '', video: '', code: '' }

    if (!this.clubConfig) {
      this.$rollbar.error('Los Cabos Clubs - Invalid Slug', this.slug);
      this.$router.push({ path: '/los-cabos-clubs', replace: true });
      return;
    }
    this.$store.dispatch('getVenue', {slug: this.slug});
    this.$store.dispatch('getClubParties', {slug: slugDecoder(this.slug)});
    // this.$store.dispatch('getMiniParties', {slug: slugDecoder(this.slug)});
    this.$store.dispatch('getLinks', {slug: 'los-cabos-clubs'});

    this.clubTagsConfig = clubsTableConfig[this.slug];
    this.clubColor = this.clubConfig.color;
    this.caption = this.clubConfig.caption || 'Los Cabos Clubs';
    this.secondaryColor = this.clubConfig.secondaryColor;
    this.logo = this.clubConfig.logo;
    this.bgPalm = this.clubConfig.bgPalm;
    this.video = this.clubConfig.video;
    this.code = this.clubConfig.code;
    this.rsvpConfig = {
      name: this.clubConfig.name, logo: this.logo + '?w=60&h=60&fit=clamp&auto=format',
      color: this.clubColor, secondaryColor: this.secondaryColor
    };
  },
};
</script>
