<template>
  <aside>
    <section
      class="col-sm-12 text-left"
      style="padding: 0px"

      :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">
      <h4 class="section-header" >
        <i class="tim-icons icon-light-3"
           style="font-size: 12px"
           :style="{color: this.primaryColor, border: `2px solid ${this.secondaryColor}`, borderRadius: '100px', padding: '5px'}"></i>
        KEEP EXPLORING
      </h4>
      <DynamicLinks :links="links"
                    :size="size"
                    :style="{ background: `linear-gradient(45deg, black, ${this.primaryColor})` }"
      ></DynamicLinks>

    </section>
    <section
      class="col-sm-12 text-left"
      style="padding: 0px"
      :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">
      <img v-lazy="this.djImage"
           height="100px" class="pull-right">
      <h4 class="section-header" >
        <i class="tim-icons icon-bulb-63"
           style="font-size: 12px"
           :style="{color: this.primaryColor, border: `2px solid ${this.secondaryColor}`, borderRadius: '100px', padding: '5px'}"></i>
        WHAT's HOT
      </h4>
      <hr style="height: 2px" :style="{ backgroundColor: this.primaryColor }"/>

      <MiniCard
        title="Taboo Los Cabos"
        text="Exquisite gourmet dining with lively Latin music !"
        link="/los-cabos-clubs/taboo-los-cabos"
        image="https://imgix.cosmicjs.com/bc3f0f60-f62b-11ee-b555-0d0678c27dd7-Taboo_Los_Cabos___tabooloscabos.jpg"
      ></MiniCard>
      <MiniCard
        title="Bagatelle Los Cabos"
        text="The perfect blend of elegant dining and shows.!"
        link="/los-cabos-clubs/bagatelle-los-cabos"
        image="https://imgix.cosmicjs.com/38c29680-f62a-11ee-b555-0d0678c27dd7-BAGATELLE_LOS_CABOS___bagatelleloscabos.jpg"
      ></MiniCard>
    </section>
    <div class="card text-center centered" style="padding: 30px">
      <small style="font-weight: lighter;font-size: 10px">CABO.PARTY</small>
    </div>

  </aside>
</template>
<script>
import DynamicLinks from "@/components/DynamicLinks"
import MiniCard from '@/components/Cards/MiniCard'

export default {
  name: 'ClubsSmartMenu',
  data: () => {
    return {
      clicked: false,
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
      sanDJ: 'https://imgix.cosmicjs.com/f9568ec0-4160-11ef-a504-63e081e4680f-santo-dj.jpg',
      tpLogo: 'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      tulumPartyLogo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png?w=105&h=15&fit=clamp'
    }
  },
  components: {DynamicLinks, MiniCard},
  props: {
    links: {
      type: Array,
      required: true
    },
    size: {
      type: String,
    },
    logo: {
      type: String,
      default: ''
    },
    primaryColor: {
      type: String,
      default: '#323232'
    },
    topSectionName: {
      type: String,
      default: 'GET IN TOUCH'
    },
    ctaText: {
      type: String,
      default: 'Hit us up!'
    },
  }
}
</script>
