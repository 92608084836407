<template>
  <card class="text-center">
    <template slot="header">
      <div v-if="this.video.youtube">
        <VideoCard :video-id="this.video.id"
                   align="center"
                   :caption="this.video.caption"
                   :content="this.video.content"
                   :title="this.video.title"
        ></VideoCard>
      </div>
      <div v-if="!this.video.youtube">
        <HlsVideoPlayer
          v-bind:title="this.video.title"
          align="center"
          v-bind:content="this.video.content"
          v-bind:video-url="this.video.videoUrl"
          v-bind:poster="this.video.poster"
        >
        </HlsVideoPlayer>
      </div>
    </template>
  </card>
</template>
<script>
import HlsVideoPlayer from '../../components/HlsVideoPlayer/HlsVideoPlayer';
import VideoCard from "@/components/Cards/VideoCard";

export default {
  name: 'ClubVideoAlbum',
  props: ['video'],
  components: {VideoCard,  HlsVideoPlayer },
  computed: {
    videos: {
      get() {
        return [];
      },
    },
  },
};
</script>
