<template>
  <div class="card" style="background-color: #161616;padding: 15px">
    <video
      loop
      preload="none"
      v-bind:src="this.videoUrlFm"
      v-bind:poster="this.poster+'?auto=format'"
      id="my-player"
      controls="controls"
      style="width: 100%; height: 300px;background-color: #0a0a0a;margin-left: auto;margin-right: auto;left: 0;right: 0;"
      crossorigin="anonymous"
    />
    <div style="padding: 20px">
    <div class="text-left" style="background-color: #292929;color: black !important;margin-bottom: 15px"
         v-if="this.mtv">
      <p  style="font-size:.8rem;font-weight: bold"
      > TULU<strong style="font-size: .8rem;color: #00f2c3;border: 1px solid aquamarine;padding: 3px">MTV</strong><br></p>
    </div>
    <h4 class="tulum-party-subtitle" v-if="this.title" style="font-size: 1.2rem">{{this.title}}</h4>
    <div v-html="this.content" style="margin-top: -12px"></div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'HlsVideoPlayer',
  props: ['videoUrl', 'poster', 'content', 'title','mtv','align'],
  computed: {
    videoUrlFm: {
      get() {
        return this.videoUrl + '?fm=hls';
      },
    },
  },
};
</script>
