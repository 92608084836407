<template>

  <div class=" card left" v-if="this.clubs.length"
       :style="{maxWidth: this.width}"
       style="padding: 5px;background-color: black;color: #61EAC8 !important;right: 0;border-left: 2px aqua solid">
    <div class="input-group-prepend">
            <span
              @click="show"
              class="tim-icons icon-zoom-split card-animation-on-hover" style="font-size: 35px;margin: 5px;padding: 5px" ></span>
      <select @change="goToClub($event)" class=" form-control form-control-lg" arial-label="Select Club" style="width: 100%;"
              v-if="this.showSearch ">
        >
        <option>Top Clubs...</option>
        <option class="form-control" v-for="(row, idx) in  this.clubs" :key="idx" v-bind:value="row.slug">
          {{row.title}}
        </option>
        <option key="events" value="events">🗓 Event Line</option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EventSearch',
  data() {
    return {
      width: '90px',
      showSearch: false
    }
  },
  props: {
    clubs: {
      type: Array, // striped | hover
      default: [],
    },
  },
  computed: {

  },
  methods: {
    show() {
      this.showSearch = !this.showSearch;
      this.width = this.showSearch ? '300px' : '90px';
    },
    goToClub(event) {
      this.$rollbar.info('QUICK CLUB INTERNAL: ' + event.target.value);
      if(event.target.value === 'events') {
        this.$router.push({path: '/#event-line', replace: true})
        return;
      }

      this.$router.push({ path: '/tulum-clubs/'+event.target.value, replace: true })

    },
  },
};
</script>
