<template>
  <div style="min-height: 400px;margin-top: 5px;padding: 20px" class="text-left">
    <span class="badge-dark">
      <img v-lazy="faqsImg+'?w=30&h=30'" height="30px" width="30px">FAQS</span>
    <br>
    <br>

    <dl class="text-left" v-for="faq in faqs">
      <dt>
        {{faq.title}}
      </dt>
      <dd v-html="faq.content">
      </dd>
    </dl>
    <h4 class="tulum-party-subtitle" v-if="!faqs.length" style="margin-top: 100px;">NO FAQS, ALL CLEAR !</h4>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'ClubFaqs',
  created: function () {
      this.$store.dispatch('getFaqsByCategory', this.clubSlug)
  },
  computed: mapState({
    faqs: (state) => {
      return state.faqs_category || [];
    },
  }),
  data: () => {
    return {
      loaded: false,
      faqsImg: 'https://imgix.cosmicjs.com/3c6580f0-6c2c-11ee-ae6f-f1c9a9c95b9c-image.png'
    };
  },
  props: ['clubSlug']

};
</script>
