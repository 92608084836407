<template>
  <div>

    <router-link  v-if="venue.slug"
      :to="{ path: venue.slug  }"
                  style="font-weight: bold"
      :style="{fontSize: this.fontSize}"
    >
<!--      <span v-if="!noIcon" class="tim-icons icon-square-pin" style="font-size: 20px;margin: 4px;background-color: #e21a6a;color: black;border-radius: 100px;">-->
      <img v-lazy="venue.logo" height="20px" width="20px" style="border-radius: 100px">
      {{ venue.title.toUpperCase() }}
    </router-link>
    <p  v-if="!venue.slug"       :style="{fontSize: this.fontSize,textTransform: 'uppercase'}" style="font-weight: bold">
      <span v-if="!noIcon" class="tim-icons icon-square-pin" style="font-size: 20px;margin: 4px;background-color: #e21a6a;color: black;border-radius: 100px;"></span>
      {{venue.title}}
    </p>

  </div>
</template>
<script>
import {venueDecoder} from "@/common/Decoder";

export default {
  name: 'EventVenue',
  props: {
    title: {
      type: String,
      description: 'Event title',
    },
    noIcon: {
      type: Boolean,
      description: 'use Icon',
      default: false,
    },
    fontSize: {
      type: String,
      description: 'font size',
      default: '0.8rem',
    }

  },
  computed: {
    venue: {
      get() {
        return this.title ? venueDecoder(this.title) : ''
      }
    },
  },
}


</script>
