<template>
  <div v-if="brand">
    <router-link
      :to="{ name: 'Brand', params: { slug: brand.slug } }"
      class="btn-link btn-sm"
      style="font-size: 0.6rem; margin-top: 5px"
    >
      <br/>
      ™️ PRESENTED BY: {{ brand.title.toUpperCase() }}
    </router-link>
  </div>


</template>
<script>
import {brandDecoder} from "@/common/Decoder";

export default {
  name: 'EventBrand',
  props: ['title'],
  computed: {
    brand: {
      get() {
        return brandDecoder(this.title);
      }
    },
  },
}


</script>
