<template>
  <div>
    <div
      class="btn-group"
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
        background-image: none;
        background-color: transparent;
      "
    >
      <a
        class="btn btn-primary"
        href="#"
        v-bind:style="{ backgroundColor: this.color }"
        v-on:click="onReservationsClick('Phone')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308a.961.961 0 0 0-.371.1 1.293 1.293 0 0 0-.294.228c-.12.113-.188.211-.261.306A2.729 2.729 0 0 0 6.9 9.62c.002.49.13.967.33 1.413.409.902 1.082 1.857 1.971 2.742.214.213.423.427.648.626a9.448 9.448 0 0 0 3.84 2.046l.569.087c.185.01.37-.004.556-.013a1.99 1.99 0 0 0 .833-.231c.166-.088.244-.132.383-.22 0 0 .043-.028.125-.09.135-.1.218-.171.33-.288.083-.086.155-.187.21-.302.078-.163.156-.474.188-.733.024-.198.017-.306.014-.373-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.401-.621a.498.498 0 0 0-.177-.041.482.482 0 0 0-.378.127v-.002c-.005 0-.072.057-.795.933a.35.35 0 0 1-.368.13 1.416 1.416 0 0 1-.191-.066c-.124-.052-.167-.072-.252-.109l-.005-.002a6.01 6.01 0 0 1-1.57-1c-.126-.11-.243-.23-.363-.346a6.296 6.296 0 0 1-1.02-1.268l-.059-.095a.923.923 0 0 1-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41a4.38 4.38 0 0 0 .263-.373c.118-.19.155-.385.093-.536-.28-.684-.57-1.365-.868-2.041-.059-.134-.234-.23-.393-.249-.054-.006-.108-.012-.162-.016a3.385 3.385 0 0 0-.403.004z"
          />
        </svg>
        <br />
        <small v-if="!this.club" style="color: black">W</small>
        <small v-if="this.club" style="color: black">WHATSAPP</small>
      </a>
      <a
        class="btn btn-primary"
        href="#"
        v-bind:style="{ backgroundColor: this.color }"
        style="font-weight: bolder; background-image: none"
        v-on:click="onReservationsClick('SMS')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
          />
        </svg>
        <br />
        <small style="color: black">SMS</small>
      </a>
      <a
        class="btn btn-primary"
        href="#"
        v-bind:style="{ backgroundColor: this.color }"
        style="font-weight: bolder; background-image: none"
        v-on:click="onReservationsClick('TG')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-3.11-8.83l-2.498-.779c-.54-.165-.543-.537.121-.804l9.733-3.76c.565-.23.885.061.702.79l-1.657 7.82c-.116.557-.451.69-.916.433l-2.551-1.888-1.189 1.148c-.122.118-.221.219-.409.244-.187.026-.341-.03-.454-.34l-.87-2.871-.012.008z"
          />
        </svg>
        <br />
        <small style="color: black">TG</small>
      </a>
    </div>
    <br />
    <a
      :href="mailto"
      class="btn-link"
      v-bind:style="{ color: this.color }"
      v-on:click="onReservationsClick('EMAIL BOOKINGS')"
    >
      Book via email</a
    >
    <br />
    <br />
    <br />
  </div>
</template>
<script>
export default {
  name: 'Reservations',
  // props: ['showBrands', 'club','color'],
  props: {
    club: {
      type: String,
      description: 'Club Name',
      default: '',
    },
    showBrands: {
      type: Boolean,
      description: 'Club Name',
    },
    color: {
      type: String,
      description: 'Club Name',
    },
  },
  data: () => {
    return {};
  },
  computed: {
    mailto() {
      return `mailto:bookings+${this.club.replaceAll(/\s/g, '-') || ''}@cabo.party`
    }
  },
  methods: {
    onReservationsClick(event) {
      let url = 'https://wa.me/524421177251';
      if (event === 'Phone') {
        if (this.club) {
          url += `?text=EVENT_INFO:%20${this.club}`;
        }
        window.open(url);
      }
      if (event === 'SMS') {
        let url = 'sms:+524421177251';
        if (this.club) {
          url += `&body=EVENT_INFO:%20${this.club}`;
        }
        window.open(url);
      }
      if (event === 'TG') {
        window.open('https://t.me/MarissaTP');
      }
      this.$gtag.event('reservations', {
        value: 30,
        event_category: 'clubs',
        event_label: this.club });
      this.$rollbar.info(`EVENT_INFO: ${this.club}`);
    },
  },
};
</script>
